import React from 'react'
import Helmet from 'react-helmet'
import * as s from './lokasena.module.scss'

export default () => {
  return (
    <>
      <Helmet title="Teymið" />
      <h2>Teymið</h2>
      <div className={s.content}>
        <p>
          <strong>Leikstjóri og handritshöfundur:</strong>
        </p>
        <p>Kristján Gauti Emilsson</p>
        <br />
        <p>
          <strong>Kvikmyndatökumaður:</strong>
        </p>
        <p>Andri Haraldsson</p>
        <br />
        <p>
          <strong>Leikari, sem Magnús:</strong>
        </p>
        <p>Aron Már Ólafsson</p>
      </div>
    </>
  )
}
